<template>
  <div class="tab">
    <div class="hastab">
      <div class="list" @click="toPage('/')" :class="pageActive == 0?'active':''">
        <img v-if="pageActive == 0" src="../../src/static/home_a.png" alt="" srcset="">
        <img v-else src="../../src/static/home.png" alt="" srcset="">
        <span>DAO</span>
      </div>
      <div @click="toPage('/me')" class="list" :class="pageActive == 1?'active':''">
        <img v-if="pageActive == 1"  src="../../src/static/Profile_a.png" alt="" srcset="">
        <img v-else src="../../src/static/Profile.png" alt="" srcset="">
        <span>ACCOUNT</span>
      </div>

      <div class="centerImg"  @click="toPage('/mint')" :class="pageActive == 2?'active':''">
        <img v-if="pageActive == 2"  src="../../src/static/Category_a.png" alt="" srcset="">
        <img v-else src="../../src/static/Category.png" alt="" srcset="">
        <span>Mint</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    pageActive:0
  },
  components: {
  },
  data() {
    return {
    }
  },
  methods: {
    toPage(url){
      this.$router.push(url)
    }
  },
};
</script>

<style>
.tab{
  width: 100%;
  height: 100px;
  position: fixed;
  bottom: 0;
  z-index: 1000;
}

.hastab{
  height: 90px;
  background: linear-gradient(180deg, #343434 0%, #000000 99%);
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
}

.hastab .list{
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #FFFFFF;
}

.hastab .active{
  color: #F5B22F;
}
.hastab .list img{
  width: 20px;
  height: auto;
}
.hastab .list span{
  margin-top: 5px;
  font-size: 12px;
}

.centerImg{
  width: 78px;
height: 78px;
border-radius: 274px;
position: absolute;
left: 50%;
top: -33px;
transform: translateX(-50%);
background-image: url("../../src/static/yueyuan.png");
background-size: 100% 100%;
z-index: 10;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
color: #FFFFFF;
  font-size: 12px;
}

.centerImg span{
  margin-top: 5px;
}
</style>
