import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/mint',
    name: 'mint',
    component: () => import('../views/mint.vue')
  },
  {
    path: '/stat',
    name: 'stat',
    component: () => import('../views/stat.vue')
  },
  {
    path: '/me',
    name: 'me',
    component: () => import('../views/me.vue')
  },
  {
    path: '/statMint',
    name: 'statMint',
    component: () => import('../views/statMint.vue')
  },
  {
    path: '/statBurn',
    name: 'statBurn',
    component: () => import('../views/statBurn.vue')
  },
  {
    path: '/server',
    name: 'server',
    component: () => import('../views/manage.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/about.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
