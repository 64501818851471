<template>
	<div class="page2">
		<div class="hotBanner">
			<div class="bg"></div>
			<div class="wenzi">
				<div class="texts">
					<div class="num">{{dataStat2[7].toFixed(2)}}</div>
					<div class="descs">全网累计已发奖励</div>
				</div>
				<div class="texts">
					<div class="num">{{dataStat3[5].toFixed(2)}}</div>
					<div class="descs">累计燃烧价值BNB</div>
				</div>
			</div>
			
		</div>


		
		<div class="huibgs">
			<div class="enterInput">
				<input type="number" v-model="burnAmount" placeholder-class="placeholder" placeholder="Enter burn amount">
			</div>
			<div class="otherText">
				<div class="li">
					<span>最小燃烧數量</span>
					<span class="cal">{{dataStat3[6]?Math.ceil(bnbPrice*dataStat3[6]):0}} {{tokenName}}</span>
				</div>
				<!-- <div class="li">
					<span>最大燃烧數量</span>
					<span class="cal">{{dataStat3[7]?Math.floor(bnbPrice*dataStat3[7]):0}}</span>
				</div> -->
				<div class="li">
					<span>燃烧数量</span>
					<span class="cal">{{burnAmount?burnAmount.toFixed(2):0}} {{tokenName}}
						<!-- <span class="xian"></span>
						<span class="max" @click="setMax">max</span> -->
					</span>
				</div>
			</div>
			<div class="whiteText">
				<!-- <div class="lists">
					<div class="nams">
						<span class="dian"></span>
						<span>本轮剩余燃烧名额：</span>
					</div>
					<div class="vals">
						09
					</div>
				</div> -->
				<div class="lists">
					<div class="nams">
						<span class="dian"></span>
						<span>预计燃烧价值（约）：</span>
					</div>
					<div class="vals">
						<img style="margin-right:5px" src="../../static/tes.png" alt="" srcset="">
						<span style="font-size: 18px;color: #FF6506;">{{(burnAmount/bnbPrice).toFixed(2)}} BNB</span>
					</div>
				</div>
				<div class="lists">
					<div class="nams">
						<span class="dian"></span>
						<span>预计可获得1.3倍收益（约）：</span>
					</div>
					<div class="vals">
						<img style="margin-right:5px" src="../../static/tes.png" alt="" srcset="">
						<span style="font-size: 18px;color: #FF6506;">{{((burnAmount/bnbPrice)*1.3).toFixed(2)}} BNB</span>
					</div>
				</div>
				<div class="smalss">
					预计数据与实际数据可能存在误差，具体以燃烧区块时间的314价格为准。
				</div>
			</div>
			
		</div>

		
		<div class="duihuan"  @click="burn" v-if="dataStat[3]>dataStat[4]">启动燃烧</div>
		<div class="duihuan" style="background-color: gray;" v-if="dataStat[3]<=dataStat[4]||getrefMap3>0">已燃烧或总次数不足</div>
		
		<div class="descBox">
		  <!-- <div class="nowhite">
		    你還不是白名單用戶，先去官方電報群裏獲取白名單資格吧！ 官方電報群
		    @xxxxxx
		  </div> -->
		  <div class="jihuo" >
		    <div class="name"  v-if="getrefMap[0]!=address0||userAddress==param[4]">自身已激活</div>
			<div class="name" @click="toPage('/mint')" v-if="getrefMap[0]==address0&&userAddress!=param[4]">前往激活></div>
		    <div class="numberBox" v-if="getrefMap[0]!=address0||userAddress==param[4]">
		      <!-- <span>{{shareUrl + "?pid=" + userAddress}}</span> -->
			  <span>{{shareUrl + "?..."}}</span>
			  
		      <span @click="copyUrl">復製URL</span>
		    </div>
		  </div>
		</div>
		
		<div class="priceBox">
			<div class="list"  >
				<div>直推已激活地址数</div>
				<div class="val">{{getrefMap[1]}}</div>
			</div>
			<div class="list" style="text-align: right;">
				<div>团队已燃烧金额</div>
				<div class="val" style="font-size: 12rpx;">{{getrefMap3[6].toFixed(4)}}BNB</div>
			</div>
			<div class="shuxian"></div>
		</div>
		 
		<div class="priceBox">
			<div class="list"  >
				<div>当前地址累计燃烧次数</div>
				<div class="val">{{getrefMap3[5]}}</div>
			</div>
			<div class="list" style="text-align: right;">
				<div>当前314协议价格</div>
				<div class="val" style="font-size: 12rpx;">{{(dataStat2[1]/ dataStat2[0]).toFixed(10)}}</div>
			</div>
			<div class="shuxian"></div>
		</div>

		<div class="rules">
			<div class="title">燃烧规则</div>
			<div class="ca">
				预留920万枚本币进入矿池，交易税2%回流矿池。燃烧用户根据其所燃烧的PMM价值按金本位（BNB总价值）的1%/天挖矿，7天产出一次。即7天产出总价值107%的PMM本币。
			</div>
			<div class="ca">1.单个地址24小时内只能燃烧一次，燃烧金额0.1BNB；</div>
			<div class="ca">2.燃烧代币销毁24%；</div>
			<div class="ca">3.推荐奖励：直推奖励4%，节点奖励2%；</div>
		</div>

	</div>
</template>

<script>
import Language from "../../lan/lan.json"; //
import { message } from "ant-design-vue";
export default {
  name: 'MaterialList',
  props: {
    // 素材数据
    dataStat: {type: Array,default() {return []}},
	getrefMap3: {type: Array,default() {return []}},
	getrefMap: {type: Array,default() {return []}},
	param: {type: Array,default() {return []}},
    bnbPrice: {type: Number,default() {return 0}},
    bnbBalance: {type: Number,default() {return 0}},
    tokenBalance: {type: Number,default() {return 0}},
    mintEnable: {type: Number,default() {return 0}},
    tokenName: {default() {return ''}},
    web3: {default() {return {}}},
    contract: {default() {return {}}},
    userAddress: {default() {return ''}},
    contractAddress: {default() {return ''}},
    dataStat2: {type: Array,default() {return []}},
    dataStat3: {type: Array,default() {return []}},
  },
  data() {
    return {
      burnAmount:null,
	  address0:'0x0000000000000000000000000000000000000000',
      urls: [],
	   shareUrl:'https://pmm.houge666.com',
    }
  },
  computed: {
    
  },
  created() {

  },
  mounted() {
    
  },
  methods: {
	  toPage(url){
	    this.$router.push(url)
	  },
	  copyUrl() {
	    var textarea = document.createElement("textarea");
	    textarea.style.position = "fixed";
	    textarea.style.opacity = 0;
	    //  if(location.href.indexOf("?")!=-1){
	    // textarea.value = location.href.substring(0,location.href.indexOf("?")) +'?pid='+this.userAddress;
	    //  }else{
	    // textarea.value = location.href+'?pid='+this.userAddress;
	    //  }
	    textarea.value = this.shareUrl + "?pid=" + this.userAddress;
	    document.body.appendChild(textarea);
	    textarea.select();
	    document.execCommand("copy");
	    document.body.removeChild(textarea);
	    message.success("success copy");
	  },
    sureUrls() {
      
    },
	async burn() {
	  if (!this.burnAmount) {
	    message.error("please enter amount!");
	    return;
	  }
	  if (parseFloat(this.burnAmount + "") > this.tokenBalance) {
	    message.error("balance is not enough");
	    return;
	  }
	  if (parseFloat(this.burnAmount + "") < this.minTransferCount) {
	    message.error("need " + this.minTransferCount);
	    return;
	  }
		console.log(this.web3,'this.web3.');
	  const value = this.web3.utils.toWei(this.burnAmount + "", "ether"); // 以太转账金额，这里以1以太为例
	  this.contract.methods
	    .burn(value)
	    .send({ from: this.userAddress })
	    .on("transactionHash", (hash) => {
	      // 在交易被发送到以太坊网络时触发的事件
	      message.success("submit success");
	      console.log("交易哈希:", hash);
	    })
	    .on("receipt", (receipt) => {
	      // 在交易被打包后确认时触发的事件
	      console.log("交易收据:", receipt);
	      //message.success(receipt.transactionHash);
	      //message.success("transaction success");
	    })
	    .on("error", (error) => {
	      // 在交易失败时触发的事件
	      message.error(error);
	      console.error("交易失败:", error);
	    });
	
	  //await this.contract.methods.band(this.bandAddress).call({});
	  //message.success('success!');
	},
	maxBurn() {
	  this.burnAmount = this.tokenBalance;
	},
	getMaxBurnAmount(){
		let value= this.dataStat3[7]?Math.floor(this.bnbPrice*this.dataStat3[7]):0;
		return parseFloat(value.toFixed(2)+'');
	},
	setMax(){
		this.burnAmount = this.getMaxBurnAmount();
	},
  }
}
</script>

<style>
.page2{
	width: 90%;
	margin: 0 auto;
}
.hotBanner{
  width: 100%;
  height: 150px;
  background-image: url("../../static/hot.png");
  background-size: 100% 100%;
  margin-top:20px;
  display: flex;
  align-items: center;
  position: relative;
}

.wenzi{
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	z-index: 10;
	display: flex;
	align-items: center;
}

.hotBanner .bg{
 position: absolute;
 left: 0;
 top: 0;
 bottom: 0;
 right: 0;
 z-index: 1;
 background: #0000006e;
}
.hotBanner .texts{
 width: 50%;
 display: flex;
 align-items: center;
 justify-content: center;
 flex-direction: column;
 color: #fff;

}

.hotBanner .num{
  font-size: 26px;
  color: #fff;
}

.descs{
	color: #ffffffa3;
	margin-top: 5px;
}

.duihuan {
	height: 40px;
	border-radius: 90px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #f5b22f;
	font-size: 18px;
	font-weight: 600;
	color: #000;
	margin-top: 20px;
  }

  .huibgs{
	width: 100%;
	height: 233px;
	background-image: url('../../static/huibg.png');
	background-size: 100% 100%;
	margin-top: 20px;
	position: relative;

  }
  .enterInput{
	position: absolute;
	right: 0;
	top: -8px;

  }

  .enterInput input{
	width: 173px;
height: 30px;
border-radius: 196.5px;
outline: none;
color: #F5B22F;
padding-left: 10px;
font-size: 15px;
background: linear-gradient(to bottom, #000000, #292929);
border: none;
text-align: center;
  }

  

  .enterInput input::placeholder {
	color: #F5B22F;
  }

  .otherText{
	box-sizing: border-box;
	padding: 20px;
	padding-top: 30px;
  }

  .otherText .li{
	display: flex;
	align-items: center;
	justify-content: space-between;

	margin-top: 4px;
  }

  .cal{
	color: #fff;
	font-size: 18px;
	display: flex;
	align-items: center;
  }

  .otherText .max {
	background: linear-gradient(180deg, #343434 3%, #000000 100%);
	box-sizing: border-box;
	border-image: linear-gradient(180deg, #151515 0%, #343434 100%) 1;
	height: 23px;
	padding: 0 4px;
	border-radius: 5px;
	color: #fff;
	font-size: 12px;
  }

  .otherText .xian{
   width: 1px;
   height: 15px;
   background: #ffffff61;
   margin: 0 10px;
   display: block;
  }


	
	.whiteText{
		padding: 20px;
		padding-top: 0;
	}
  .whiteText .lists{
	display:flex;
	align-items: center;
	justify-content: space-between;
	color: #fff;
	margin-bottom: 3px;
	padding-right:10px;
  }

  .whiteText .lists .dian{
	width: 8px;
	height: 8px;
	background: #fff;
	border-radius: 50%;
	display: block;
	margin-right: 10px;
  }

  .whiteText .lists .nams{
	display: flex;
	align-items: center;
  }
  
  .smalss{
	font-size: 8px;
	color: #ffffffc9;
  }

  .priceBox{
	width: 100%;
	height: 84px;
	background: rgba(255, 255, 255, 0.3);
	border-radius: 10px;
	backdrop-filter: blur(5px);
	margin-top: 20px;
	display: flex;
	align-items: center;
	padding: 0 20px;
	position: relative;
	
  }

  .shuxian{
	width: 1px;
	height: 50px;
	background: #fff;
	position: absolute;
	left: 55%;
  }

  .priceBox .list{
	width: 50%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	font-size: 14px;
	color: #fff;
  }

  .priceBox .list .val{
	font-size: 20px;
font-weight: 600;
color: #FFFFFF;
  }

  .rules{
	width: 100%;
	background: linear-gradient(180deg, #343434 0%, #000000 95%);
	height: 241px;
	border-radius: 10px;
	color: #fff;
	box-sizing: border-box;
	padding: 20px;
	margin-top: 20px;
  }

  .rules .title{
	text-align: center;
	margin-bottom: 10px;
	font-size: 14px;
	color: #F5B22F;
	letter-spacing: 0.31em;
  }

  .rules .ca{
	font-size: 12px;
	color: #ffffffc2;
	margin-top: 3px;
  }
  
  .descBox {
    width: 95%;
    margin: 0 auto;
    margin-top: 20px;
  }
  
  .jihuo .name {
    text-align: center;
    font-size: 14px;
    font-weight: normal;
    color: #f5b22f;
  }
  
  .numberBox {
    width: 100%;
    height: 34px;
    background: #1d1c1f;
    box-sizing: border-box;
    border-image: linear-gradient(180deg, #070707 0%, #2f3138 98%) 2;
    color: rgba(255, 255, 255, 0.7);
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    border-radius: 30px;
    margin-top: 20px;
  }
</style>

