<template>
  <div class="homePage">
    <div class="logo">
      <img class="logoImg" @click="showLang = true" src="../../src/static/logo.png" alt="" srcset="" />
      <div class="lianjie">
        <span v-if="!userAddress">连接</span>
      		<span v-if="userAddress">{{userAddress.substring(0,10)+'...'+userAddress.substring(36)}}</span>
        <div class="quan" v-if="!userAddress">
          <img src="../../src/static/youshang.png" alt="" srcset="">
        </div>
      </div>
    </div>

    <div class="tabIndex">
      <div @click="actIndex = 0" :class="actIndex == 0 ? 'active' : ''">
        交易
      </div>
      <div
        @click="actIndex = 1"
        :class="actIndex == 1 ? 'active' : ''"
        style="margin: 0 15px"
      >
        燃烧
      </div>
      <div @click="actIndex = 2" :class="actIndex == 2 ? 'active' : ''">
        仪表盘
      </div>
    </div>
    <page1 v-if="actIndex == 0&&userAddress" :dataStat="dataStat" :dataStat2="dataStat2" :dataStat3="dataStat3"
	 :bnbPrice="bnbPrice" :tokenName="tokenName" :bnbBalance="bnbBalance" :tokenBalance="tokenBalance"
	  :mintEnable="mintEnable" :web3="web3" :contract="contract"  :contractAddress="contractAddress" :userAddress="userAddress"
	  @loadData="loadData" ></page1>
    <page2 v-if="actIndex == 1&&userAddress" :dataStat="dataStat" :dataStat2="dataStat2" :dataStat3="dataStat3"
	 :bnbPrice="bnbPrice" :tokenName="tokenName" :bnbBalance="bnbBalance" :tokenBalance="tokenBalance"
	  :mintEnable="mintEnable" :web3="web3"  :contract="contract" :contractAddress="contractAddress" :userAddress="userAddress"
	  @loadData="loadData" :getrefMap3="getrefMap3" :getrefMap="getrefMap" :param="param"></page2>
    <page3 v-if="actIndex == 2" :dataStat="dataStat" :dataStat2="dataStat2" :dataStat3="dataStat3" :bnbPrice="bnbPrice"></page3>

    <div style="height:200px"></div>

    <tab :pageActive="0"></tab>

      <lang v-if="showLang" @checkClose="showLang = false"></lang>
  </div>
</template>

<!-- lang="ts" -->
<script>
import Web3 from "web3";
import MyContractABI from "../abi/main.json"; // 导入合约 ABI
import Language from "../lan/lan.json"; //
import { message } from "ant-design-vue";
import page1 from "./home/page1.vue";
import page2 from "./home/page2.vue";
import page3 from "./home/page3.vue";
import tab from "../../src/components/tab.vue";
import lang from "../../src/components/lang.vue";
import Spin from '@/utils/spinLoading.js'
export default {
  // computed: {
  //   author () {
  //     return process.env.VUE_APP_AUTHOR;
  //   },
  //   apiBaseUrl () {
  //     return process.env.VUE_APP_API_BASE_URL;
  //   }
  // }
  components: {
    page1,
    page2,
    page3,
    tab,
    lang
  },
  data() {
    return {
      showLang:false,
      actIndex: 0,
      defaultProps: {
        children: "children",
        label: "label",
      },

      //要修改的参数
      contractAddress: "0xe754bf71cfef48d116909f00a78a63d4416104ab",
      //networkId:97,
      networkId: 56,
      //end
      userAddress: "",
      dataStat: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      tokenName: "PMM",
      mintAmount: 0,
      price: 0,
      dataStat2: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      dataStat3: [this.address0,this.address0,this.address0, 0,0,0, 0, 0],
      getrefMap: [this.address0,0,0,0,0,0,0,],
      getrefMap2: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
	  getrefMap3: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      param: [0,0,0,this.address0,this.address0,0,-1, 0, 0,0,],
      exchangeAmount: 0,
      exchangeAmount2: 0,
	  loaded:false,
      data2: {},
      bnbBalance: 0,
      tokenBalance: 0,
	  mintEnable:0,
	  bnbPrice:0,
	  usdtPrice:0,
      web3: {},
	  address0:"0x0000000000000000000000000000000000000000",
      type: "buy",
      showMore: false,
      lanageShow: false,
      notTo: "0",
      Language: Language,
      selectLan: "en",
    };
  },
  created() {
    if (localStorage.getItem("language")) {
      this.selectLan = localStorage.getItem("language");
    }
    if (this.$route.query.notTo) {
      this.notTo = this.$route.query.notTo;
    }
    if (this.$route.query.pid) {
      this.toPage("/mint?pid=" + this.$route.query.pid);
    }
    if (this.$route.query.manage) {
      this.toPage("/manage");
    } else {
      this.connectBSC();
      let that = this;
      this.interval = setInterval(function () {
        that.loadData();
      }, 1000 * 15);
    }
  },
  destroyed() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  },
  methods: {
    changeLang(){

    },
    changeLan(key) {
      this.selectLan = key;
      this.lanageShow = false;
      localStorage.setItem("language", this.selectLan);
    },
    changeAmount() {
      if (this.type == "buy") {
        this.exchangeAmount2 = (
          this.exchangeAmount *
          (this.dataStat2[0] /
            (this.dataStat2[1] + parseFloat(this.exchangeAmount + ""))) *
          1
        ).toFixed(2);
      } else {
        this.exchangeAmount2 = (
          (this.exchangeAmount /
            ((this.dataStat2[0] + parseFloat(this.exchangeAmount + "")) /
              this.dataStat2[1])) *
          1
        ).toFixed(8);
      }
    },
    changeAmount2(value) {
      if (this.type == "buy") {
        this.exchangeAmount = (
          this.exchangeAmount2 /
          (this.dataStat2[0] / this.dataStat2[1])
        ).toFixed(8);
      } else {
        this.exchangeAmount = (
          this.exchangeAmount2 *
          (this.dataStat2[0] /
            (this.dataStat2[1] + parseFloat(this.exchangeAmount2 + ""))) *
          1
        ).toFixed(2);
      }

      // console.log(value.target,'exchange value');
      // if(this.type == 'buy'){

      // 	//let b = exchangeAmount/((100000+parseFloat(exchangeAmount+''))/2))
      // 	//如果 b = 1000; 求exchangeAmount =多少的代码怎么写？

      // 	// let b = 1000;
      // 	// let exchangeAmount = (b * 2) / (1 + (2/b)) - 100000;

      // 	// let b = 1000;
      // 	// let exchangeAmount = (b * 100000) / (2 - b);

      // 	let b = parseFloat(this.exchangeAmount2+'');
      // 	//let exchangeAmount = (b * this.dataStat2[1]) / (1 + (this.dataStat2[1]/b)) - this.dataStat2[0];
      // 	//let exchangeAmount = (b * this.dataStat2[0]) / (this.dataStat2[1] + b);
      // 	let exchangeAmount = (b * this.dataStat2[1]) / (this.dataStat2[0] * b - 1);
      // 	this.exchangeAmount = parseFloat(exchangeAmount.toFixed(8));
      // 	//(exchangeAmount*(dataStat2[0]/(dataStat2[1]+parseFloat(exchangeAmount+'')))*1).toFixed(2)
      // }else{

      // }
    },
    exchange() {
      if (this.type == "buy") {
        if (!this.exchangeAmount) {
          message.error("please enter amount!");
          return;
        }

        const value = this.web3.utils.toWei(this.exchangeAmount + "", "ether"); // 以太转账金额，这里以1以太为例

        this.web3.eth.sendTransaction(
          {
            from: this.userAddress,
            to: this.contractAddress,
            value: value,
          },
          (error, transactionHash) => {
            if (!error) {
              //message.error('error:'+transactionHash);
              //message.success(transactionHash);
              message.success("transaction success");
              console.log(transactionHash); // 交易哈希
              this.exchangeAmount = 0;
            } else {
              //message.error(error);
              console.error(error);
            }
          }
        );
      } else {
        const value = this.web3.utils.toWei(this.exchangeAmount + "", "ether"); // 以太转账金额，这里以1以太为例

        this.contract.methods
          .transfer(this.contractAddress, value)
          .send({ from: this.userAddress })
          .on("transactionHash", (hash) => {
            // 在交易被发送到以太坊网络时触发的事件
            message.success("submit success");
            console.log("交易哈希:", hash);
          })
          .on("receipt", (receipt) => {
            // 在交易被打包后确认时触发的事件
            console.log("交易收据:", receipt);
            this.exchangeAmount = 0;
            //message.success(receipt.transactionHash);
            message.success("transaction success");
            this.loadData();
          })
          .on("error", (error) => {
            // 在交易失败时触发的事件
            //message.error(error);
            console.error("交易失败:", error);
          });
      }
    },
    changeType() {
      this.type = this.type == "buy" ? "sell" : "buy";
      this.exchangeAmount = 0;
      this.exchangeAmount2 = 0;
    },
    toPage(url) {
      this.$router.push(url);
    },
    changeLanage() {
      if (this.lanageShow == false) {
        this.lanageShow = true;
      } else if (this.lanageShow == true) {
        this.lanageShow = false;
      }
    },
    close() {
      this.showMore = false;
    },
    open() {
      this.showMore = true;
    },
    changeTap() {
      console.log("123");
    },
    maxBuy() {
      this.exchangeAmount = this.bnbBalance;
      this.changeAmount();
    },
    maxSell() {
      this.exchangeAmount = this.tokenBalance;
      this.changeAmount2();
    },
    async connectBSC() {
      // if (window.ethereum) {
      //     await window.ethereum.request({method: 'eth_requestAccounts'});
      //     window.web3 = new Web3(window.ethereum);
      //     return true;
      //   }
      let that = this;
      if (window.ethereum) {
        try {
          window.ethereum.on("accountsChanged", function (accounts) {
            // accounts 是一个数组，包含当前连接的所有账户
            console.log("change address:", accounts[0]);
            that.connectBSC();
            // 在这里处理账户变更后的逻辑
            // 例如更新界面上显示的账户信息等
          });
          console.log(window.ethereum, "window.ethereum");
          // 请求用户授权连接到BSC
          const accounts = await window.ethereum.request({
            method: "eth_requestAccounts",
          });

          console.log(accounts, "accounts");

          // 创建web3实例
          this.web3 = new Web3(window.ethereum);

          // 获取用户地址
          this.userAddress = accounts[0].toLowerCase(); // 更新用户地址
		  localStorage.setItem('userAddress',this.userAddress);

          let bnbBalance = await this.web3.eth.getBalance(this.userAddress);
		  console.log(bnbBalance,'bnbBalance');
          this.bnbBalance = parseFloat(this.web3.utils.fromWei(bnbBalance, "ether"));

          // 加载合约
          console.log(MyContractABI, "MyContractABI");
          const networkId = await this.web3.eth.net.getId();
          if (networkId != this.networkId) {
            message.error("err network，please select BNB chain");
          }
          //const deployedNetwork = MyContractABI.networks[networkId];
          this.contract = new this.web3.eth.Contract(
            MyContractABI,
            // deployedNetwork && deployedNetwork.address
            this.contractAddress
          );

          this.connected = true;

          this.loadData(true);
        } catch (error) {
          console.error(error);
        }
      } else {
        // alert("Please install MetaMask or a BSC-compatible wallet to use this DApp.");
      }
    },
    async loadData(toPageFlag) {
      if (this.contract) {
        try {
          // 调用合约方法
		  if(!this.loaded){
			  Spin.show('Loading');
		  }
          let dataStat = await this.contract.methods.getStatData().call({});
          let dataStat2 = await this.contract.methods.getStatData2().call({});
          let dataStat3 = await this.contract.methods.getStatData3().call({});
          let param = await this.contract.methods.getParam().call({});
          let getrefMap = await this.contract.methods.getrefMap(this.userAddress).call({});
		  let getrefMap3 = await this.contract.methods.getrefMap3(this.userAddress).call({});
          let balanceOf = await this.contract.methods.balanceOf(this.userAddress).call({});

          balanceOf = parseFloat(this.web3.utils.fromWei(balanceOf, "ether"));

          param[1] = this.web3.utils.fromWei(param[1], "ether");
          param[2] = this.web3.utils.fromWei(param[2], "ether");
          param[3] = param[3].toLowerCase();
          param[4] = param[4].toLowerCase();
          param[5] = this.web3.utils.fromWei(param[5], "ether");
          param[6] = this.web3.utils.fromWei(param[6], "ether");

          if (toPageFlag) {
            if (param[6] > 0 && this.notTo == "0") {
              //还在私募阶段
              //this.toPage("/mint");
            }
          }

          getrefMap[6] = this.web3.utils.fromWei(getrefMap[6], "ether");
          getrefMap[4] = this.web3.utils.fromWei(getrefMap[4], "ether");
		  
		  dataStat[1] = parseFloat(this.web3.utils.fromWei(dataStat[1], "ether"));
		  dataStat[8] = parseInt(dataStat[8]);
		  dataStat[3] = parseInt(dataStat[3]);
		  dataStat[4] = parseInt(dataStat[4]);

          dataStat2[0] = parseFloat(this.web3.utils.fromWei(dataStat2[0], "ether"));
          dataStat2[1] = parseFloat(this.web3.utils.fromWei(dataStat2[1], "ether") );
		  this.bnbPrice = dataStat2[1]!=0&&dataStat2[0]!=0?dataStat2[0]/dataStat2[1]:0;
		  dataStat2[3] = parseFloat(this.web3.utils.fromWei(dataStat2[3], "ether"));
		  dataStat2[4] = parseFloat(this.web3.utils.fromWei(dataStat2[4], "ether"));
		  dataStat2[7] = parseFloat(this.web3.utils.fromWei(dataStat2[7], "ether"));

          dataStat3[5] = parseFloat(this.web3.utils.fromWei(dataStat3[5], "ether"));
		  dataStat3[6] = parseFloat(this.web3.utils.fromWei(dataStat3[6], "ether"));
		  dataStat3[7] = parseFloat(this.web3.utils.fromWei(dataStat3[7], "ether"));
		  dataStat3[8] = parseFloat(dataStat3[8]);
		  
		  
		  getrefMap3[5] = parseInt(getrefMap3[5]);
		  getrefMap3[3] = parseFloat(this.web3.utils.fromWei(getrefMap3[3], "ether"));
		  getrefMap3[6] = parseFloat(this.web3.utils.fromWei(getrefMap3[6], "ether"));

          this.dataStat = dataStat;
          this.dataStat2 = dataStat2;
          this.dataStat3 = dataStat3;
          this.param = param;
          this.getrefMap = getrefMap;
          this.tokenBalance = balanceOf;
		  this.mintEnable = dataStat[8];
		  this.getrefMap3 = getrefMap3;
		  
		  if(!this.loaded){
			  this.loaded = true;
		      Spin.hide();
		  }

          // console.log(this.dataStat,"dataStat:");
          // console.log(this.dataStat2,"dataStat2:");
          // console.log(this.dataStat3,"dataStat3:");
          // console.log(this.param,"param:" );
          // console.log(this.getrefMap, "this.getrefMap");
        } catch (error) {
          console.error(error);
        }
      } else {
        console.error("Contract not initialized.");
      }
    },
  },
};
</script>


<style>
body {
  background-image: url("../static/bg2.jpg");
  background-size: 100% 100%;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.thenUnSelect {
  width: 100%;
  height: 32px;
  padding: 6px 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  border-radius: 6px;
  margin: 5px 0;
  color: #4c5e70;
}
.thenSelected {
  width: 100%;
  height: 32px;
  padding: 6px 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  border-radius: 6px;
  margin: 5px 0;
  color: #31b6f0;
}
.rotate {
  position: absolute;
  top: -15px;
  left: -10px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  text-align: center;
  line-height: 28px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  transition: transform 0.5s ease;
}
.rotate:active {
  transform: rotate(210deg);
}
.top {
  width: 90%;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
}

.second01 {
  width: 90%;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}

.second02 {
  font-size: 16px;
  color: #7d7d7d;
}

.second03 {
  width: 20px;
  height: 20px;
}

.second04 {
  width: 90%;
  margin: 18px auto;
  height: 90px;
  display: block;
  margin-top: 1px;
  border-radius: 12px;
  border: 2px solid #538cce;
  background: rgba(9, 45, 86, 0.92);
}

.second05 {
  width: 90%;
  margin: 12px auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.second09 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 39px;
  height: 25px;
  border-radius: 6px;
  text-align: center;
  line-height: 20px;
  background-color: #497dbd;
  color: #fff;
  font-size: 12px;
  margin-left: 10px;
}

.first {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.first01 {
  width: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.first02 {
  width: 100%;
  border-bottom: 1px solid gray;
}

.first03 {
  width: 70px;
  height: 70px;
  margin-left: 30px;
  margin-right: 15px;
}

.first04 {
  font-size: 32px;
  font-weight: 600;
}

.top-right {
  width: 180px;
  height: 35px;
  border-radius: 18px;
  text-align: center;
  line-height: 35px;
  background: #497dbd;
  color: #fff;
  font-size: 12px;
}

.second {
  width: 90%;
  margin: 10px auto;
  display: block;
  border-radius: 20px;
  border: 3px solid #4f82b4;
}

.desc {
  text-align: center;
  color: #7ca0d6;
  font-size: 18px;
}

.imglist {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 85%;
  margin-top: 20px;
}

.imglist img {
  width: 70px;
  height: auto;
}

.iconlist {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 17%;
  margin-bottom: 40px;
  margin-top: 20px;
}

.iconlist img {
  width: 20px;
  height: auto;
}

.mycode {
  text-align: center;
  font-size: 27px;
  color: #7ca0d6;
  font-weight: bold;
  margin-top: 30px;
}

.homePage {
  box-sizing: border-box;
  position: fixed;
  height: 100vh;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  overflow-y: scroll;
}

.homePage .logo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
}

.logoImg {
  width: 112px;
  height: auto;
}

.tabIndex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tabIndex div {
  height: 30px;
  border-radius: 196.5px;
  background: #ffffff3b;
  padding: 0 20px;
  color: #fff;
  line-height: 30px;
}

.tabIndex .active {
  background: #f5b22f;
  color: #000;
}


.lianjie{
  width: 152px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 50px;
  padding: 0 5px 0 15px;
  border: 1px solid #fff;
  color: #fff;
  font-size: 12px;
}

.lianjie .quan{
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lianjie .quan img{
  width: 17px;
  height: auto;
}

.dialogClass .ant-modal-footer{
  display: none;
}
</style>