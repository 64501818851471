<template>
  <div class="page1">
    <div class="huanBox" >
      <div class="rs1" v-if="type=='buy'">
        <div class="max" @click="maxBuy()">max</div>
        <div class="list">
          <div class="title">支付</div>
          <div class="vca">
            <a-dropdown>
              <div class="ant-dropdown-link" @click.prevent>
                BNB
                <!-- <img style="width: 8px; margin-left: 5px" src="../../static/xia.png" alt="" srcset=""/> -->
              </div>
            </a-dropdown>
          </div>
        </div>
        <div class="list">
          <div class="title">余额：{{bnbBalance.toFixed(2)}}</div>
          <div class="vca"> <input placeholder="0" type="number" style="width: 100px;text-align: right;font-size: 20px;font-weight: 500;color: #80adfb;border: none;background: none;" @input="changeAmount" v-model="exchangeAmount" /></div>
        </div>
      </div>
	  <div class="rs1" v-if="type=='sell'">
	    <div class="max" @click="maxBuy()">max</div>
	    <div class="list">
	      <div class="title">支付</div>
	      <div class="vca">
	        <a-dropdown>
	          <div class="ant-dropdown-link" @click.prevent>
				  {{tokenName}}
	            <!-- <img style="width: 8px; margin-left: 5px" src="../../static/xia.png" alt="" srcset=""/> -->
	          </div>
	        </a-dropdown>
	      </div>
	    </div>
	    <div class="list">
	      <div class="title">余额：{{tokenBalance.toFixed(2)}}</div>
	      <div class="vca"> <input placeholder="0" type="number" style="width: 100px;text-align: right;font-size: 20px;font-weight: 500;color: #80adfb;border: none;background: none;" @input="changeAmount" v-model="exchangeAmount" /></div>
	    </div>
	  </div>
	  
	  
      <div class="qiu" @click="changeType()" >
        <img src="../../static/huan.png" alt="" srcset="" />
      </div>
	  
	  
      <div class="rs2" v-if="type=='buy'">
        <div class="list">
          <div class="title">接收</div>
          <div class="vca">
            <a-dropdown>
              <div class="ant-dropdown-link" @click.prevent>
                {{tokenName}}
                <!-- <img style="width: 8px; margin-left: 5px" src="../../static/xia.png" alt="" srcset=""/> -->
              </div>
            </a-dropdown>
          </div>
        </div>
        <div class="list">
          <div class="title">余额：{{tokenBalance.toFixed(2)}}</div>
          <div class="vca"><input placeholder="0" type="number" style="width: 100px;text-align: right;font-size: 20px;font-weight: 500;color: #80adfb;border: none;background: none;" @input="changeAmount2" v-model="exchangeAmount2" /></div>
        </div>
      </div>
	  <div class="rs2" v-if="type=='sell'">
	    <div class="list">
	      <div class="title">接收</div>
	      <div class="vca">
	        <a-dropdown>
	          <div class="ant-dropdown-link" @click.prevent>
	            BNB
	            <!-- <img style="width: 8px; margin-left: 5px" src="../../static/xia.png" alt="" srcset=""/> -->
	          </div>
	        </a-dropdown>
	      </div>
	    </div>
	    <div class="list">
	      <div class="title">余额：{{bnbBalance.toFixed(2)}}</div>
	      <div class="vca"><input placeholder="0" v-model="exchangeAmount2" style="width: 100px;text-align: right;font-size: 20px;font-weight: 500;color: #80adfb;border: none;background: none;" @input="changeAmount2" /></div>
	    </div>
	  </div>
	  
    </div>

    <div class="duihuan" @click="exchange()">兑换</div>
    <div class="kineText">
      <div class="icon"></div>
      <div class="dengyu">
        <!-- <span>≈$0.415</span> -->
		<span v-if="mintEnable >=4">1BNB ≈ {{bnbPrice.toFixed(2)}} {{tokenName}}</span>
		<span v-if="mintEnable < 4">1BNB ≈ ? {{tokenName}}</span>
		
		<!-- <br>
		
		<span v-if="mintEnable >=4">1{{tokenName}} ≈ {{(dataStat3[8]/bnbPrice).toFixed(8)}} USDT</span>
		<span v-if="mintEnable < 4">1{{tokenName}} ≈ ? USDT</span>
        <span class="green small" v-show="false">+2.25%</span> -->
      </div>
      <div class="timeCheck" v-show="false">
        <span @click="timeIndex = index" :class="timeIndex == index?'active':''" v-for="(item,index) in timelist" :key="index">{{item}}</span>
      </div>
    </div>
    <div id="chart" style="width: 100%; height: 300px; margin-top: 30px"  v-show="false"/>
  </div>
</template>


<script >
import { init, dispose } from "klinecharts";
import Language from "../../lan/lan.json"; //
import { message } from "ant-design-vue";
export default {
		// computed: {
		//   author () {
		//     return process.env.VUE_APP_AUTHOR;
		//   },
		//   apiBaseUrl () {
		//     return process.env.VUE_APP_API_BASE_URL;
		//   }
		// }
		components: {
			
		},
		props: {
			dataStat: {type: Array,default() {return []}},
			bnbPrice: {type: Number,default() {return 0}},
			bnbBalance: {type: Number,default() {return 0}},
			tokenBalance: {type: Number,default() {return 0}},
			mintEnable: {type: Number,default() {return 0}},
			tokenName: {default() {return ''}},
			web3: {default() {return {}}},
			contract: {default() {return {}}},
			userAddress: {default() {return ''}},
			contractAddress: {default() {return ''}},
			dataStat2: {type: Array,default() {return []}},
		    dataStat3: {type: Array,default() {return []}},
		},
		data() {
			return {
				timelist:['1分','5分','15分','1时','4时','1日','1周'],
				timeIndex:0,
				type:"buy",
				exchangeAmount: 0,
				exchangeAmount2:0,
				data2: {},
			}
		},
		methods: {
			changeAmount(){
				if(this.type=='buy'){
					this.exchangeAmount2 = (this.exchangeAmount*(this.dataStat2[0]/(this.dataStat2[1]+parseFloat(this.exchangeAmount+'')))*1).toFixed(2)
				}else{
					this.exchangeAmount2 = ((this.exchangeAmount/((this.dataStat2[0]+parseFloat(this.exchangeAmount+''))/this.dataStat2[1]))*1).toFixed(8);
				}
				
			},
			changeAmount2(value){
				if(this.type=='buy'){
					this.exchangeAmount = (this.exchangeAmount2/(this.dataStat2[0]/this.dataStat2[1])).toFixed(8);
				}else{
					this.exchangeAmount = (this.exchangeAmount2*(this.dataStat2[0]/(this.dataStat2[1]+parseFloat(this.exchangeAmount2+'')))*1).toFixed(2)
				}
			
			},
			exchange(){
				if(this.type == 'buy'){
					if(!this.exchangeAmount){
						message.error('please enter amount!')
						return;
					}
					
					const value = this.web3.utils.toWei(this.exchangeAmount+'', 'ether'); // 以太转账金额，这里以1以太为例
					 console.log(this.userAddress,'this.userAddress');
					this.web3.eth.sendTransaction({
					  from: this.userAddress,
					  to: this.contractAddress,
					  value: value
					}, (error, transactionHash) => {
					  if (!error) {
						//message.error('error:'+transactionHash);
						//message.success(transactionHash);
						message.success("transaction success");
					    console.log(transactionHash); // 交易哈希
						this.exchangeAmount =0;
					  } else {
						message.error(error);
					    console.error(error);
					  }
					});
				}else{
					const value = this.web3.utils.toWei(this.exchangeAmount+'', 'ether'); // 以太转账金额，这里以1以太为例
					
					this.contract.methods.transfer(this.contractAddress,value).send({ from: this.userAddress })
					  .on('transactionHash', (hash) => {
					    // 在交易被发送到以太坊网络时触发的事件
						message.success("submit success");
					    console.log('交易哈希:', hash);
					  })
					  .on('receipt', (receipt) => {
					    // 在交易被打包后确认时触发的事件
					    console.log('交易收据:', receipt);
						this.exchangeAmount =0;
						//message.success(receipt.transactionHash);
						message.success("transaction success");
						//this.loadData();
					  })
					  .on('error', (error) => {
					    // 在交易失败时触发的事件
						message.error(error);
					    console.error('交易失败:', error);
					  });
				}
			},
			changeType(){
				console.log('aaaaaaaaa');
				this.type = this.type == 'buy'?'sell':'buy';
				this.exchangeAmount = 0;
				this.exchangeAmount2 = 0;
			},
			changeTap(){
				console.log('123');
			},
			maxBuy() {
				this.exchangeAmount = this.bnbBalance;
				this.changeAmount();
			},
			maxSell() {
				this.exchangeAmount = this.tokenBalance;
				this.changeAmount2();
			},
		},
		
		mounted() {
		  const option = {
			crosshair: {
			  show: true,
			  // 十字光标水平线及文字
			  horizontal: {
				show: true,
				line: {show: true,style: "solid",size: 1,color: "#333",},
			
			  },
			  vertical: {
				show: true,
				line: {show: true,style: 'solid',size: 1,color: '#333'},
			  },
			},
      }


      const chart = init("chart");
      chart.setStyles(option);
      chart.applyNewData([
        {close: 4976.16,high: 4977.99,low: 4970.12,open: 4972.89,timestamp: 1587660000000,volume: 204,},
        {close: 4977.33,high: 4979.94,low: 4971.34,open: 4973.2,timestamp: 1587660060000,volume: 194,},
        {close: 4977.93,high: 4977.93,low: 4974.2,open: 4976.53,timestamp: 1587660120000,volume: 197,},
        {close: 4966.77,high: 4968.53,low: 4962.2,open: 4963.88,timestamp: 1587660180000,volume: 28,},
        {close: 4961.56,high: 4972.61,low: 4961.28,open: 4961.28,timestamp: 1587660240000,volume: 184,},
        {close: 4964.19,high: 4964.74,low: 4961.42,open: 4961.64,timestamp: 1587660300000,volume: 191,},
        {close: 4968.93,high: 4972.7,low: 4964.55,open: 4966.96,timestamp: 1587660360000,volume: 105,},
        {close: 4979.31,high: 4979.61,low: 4973.99,open: 4977.06,timestamp: 1587660420000,volume: 35,},
        {close: 4977.02,high: 4981.66,low: 4975.14,open: 4981.66,timestamp: 1587660480000,volume: 135,},
        {close: 4985.09,high: 4988.62,low: 4980.3,open: 4986.72,timestamp: 1587660540000,volume: 76,},
      ]);
		},
		
	}

</script>
<style>
.page1 {
  width: 90%;
  margin: 0 auto;
  margin-top: 30px;
}
.rs1 {
  width: 100%;
  height: 100px;
  background-image: url("../../static/rsbg1.png");
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  position: relative;
}
.rs1 .list {
  width: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 13%;
}
.rs1 .list div {
  color: #fff;
}

.rs1 .list .title {
  font-size: 12px;
}

.rs1 .list .vca {
  font-size: 18px;
  font-weight: 600;
}

.rs1 .max {
  background: linear-gradient(180deg, #343434 3%, #000000 100%);
  box-sizing: border-box;
  border-image: linear-gradient(180deg, #151515 0%, #343434 100%) 1;
  height: 23px;
  padding: 0 4px;
  position: absolute;
  right: 13px;
  top: 25px;
  border-radius: 5px;
  color: #fff;
  font-size: 12px;
}

.rs2 {
  width: 100%;
  height: 100px;
  background-image: url("../../static/rsbg2.png");
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 10px;
}
.rs2 .list {
  width: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 13%;
}
.rs2 .list div {
  color: #fff;
}

.rs2 .list .title {
  font-size: 12px;
}

.rs2 .list .vca {
  font-size: 18px;
  font-weight: 600;
}

.huanBox {
  position: relative;
}

.qiu {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 53px;
  height: 53px;
  background: #f5b22f;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index:1000
}

.duihuan {
  height: 40px;
  border-radius: 90px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5b22f;
  font-size: 18px;
  font-weight: 600;
  color: #000;
  margin-top: 20px;
}

.kineText {
  color: #fff;
  margin-top: 30px;
}

.dengyu {
  font-size: 20px;
  color: #ffffff;
}

.dengyu .small {
  font-size: 14px;
  margin-left: 10px;
}

.dengyu .green {
  color: #15bd75;
}

.timeCheck span{
  margin-right: 10px;
  background: #000;
  color: #fff;
  font-size: 10px;
  padding: 4px 8px;
  border-radius: 20px;
}

.timeCheck .active{
  background: #F5B22F;
  color: #000;
}
</style>
