<template>
  <div class="labs">
    <div class="mask"></div>
    <div class="lang">
      <div class="langlist">
        <div style="">
          <div class="titl">語言</div>
        </div>
        <div class="lists">
          <div>English</div>
          <div>中文繁體</div>
          <div>中文简体</div>
        </div>
      </div>
  
      <div class="close" @click="checkClose">
        <img src="../../src/static/close.png" alt="" srcset="" />
      </div>
    </div>
  </div>
  
</template>

<script>
export default {
  props: {},
  components: {},
  data() {
    return {};
  },
  methods: {
    checkClose(){
      this.$emit('checkClose')
    }
  },
};
</script>

<style>
.lang {
  width: 80%;
  height: 308px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

.mask{
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9;
  background: #0000008a;
}

.langlist {
  background: #2b2e37;
  height: 240px;
  border-radius: 10px;
  border: 1px solid #f5b22f;
}

.langlist .titl {
  height: 30px;
  border-radius: 196.5px;
  font-size: 15px;
  font-weight: 600;
  color: #000000;
  background: #f5b22f;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 15px;
}

.langlist .lists {
  padding: 0 20px;
}

.langlist .lists div {
  height: 30px;
  font-size: 15px;
  border-radius: 196.5px;
  background: #ffffff33;
  margin-top: 13px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 30px;
}
</style>
